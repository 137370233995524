import React from "react";
import icon from '../icon.jpg'
import './imgBanner.css'
function ImgBanner () {

    return (
        <div className="imgBanner">
            <img src={icon}></img>
        </div>
    )
}

export default ImgBanner;