import React from "react";
import './banner.css'
function Banner() {
    return (
        <div className="banner">
            <h1>Banner</h1>
            </div>
    )
}

export default Banner;