
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Banner from './content/Banner';
import Main from './page/Main';
function App() {
  return (
    <>
    <Main></Main>
    </>
  );
}

export default App;
