import React from "react";
import './footer.css'
function Footer() {
    return (
        <footer>
            <div className="userItems">
            <a href='https://shop.alsopss.com' className='icon'>
                <i class="bi bi-shop"></i>
               </a>
               <a href='https://games.alsopss.com' className='icon'>
                <i class="bi bi-controller"></i>
               </a>
            </div>
        </footer>
    )
}

export default Footer

