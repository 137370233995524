import React, { useEffect, useRef, useState } from "react";
import Banner from "../content/Banner";
import Header from "./Header";
import './main.css'
import icon from '../icon.jpg'
import ImgBanner from "./ImgBanner";
import Footer from "./Footer";
import logo from '../Cart.png'
import LScreen from './LScreen'
function Main() {
    const [isMobile, setIsMobile] = useState(false);
    const aboutInfoRef = useRef(null);
    const [pageLoaded, setPageLoaded] = useState(false)
  
    useEffect(() => {
      const handleResize = () => {
        const isMobileQuery = window.matchMedia('(max-width: 767px)');
        setIsMobile(isMobileQuery.matches);
      };
  
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      // Set pageLoaded to true when component mounts
      setPageLoaded(true);

      // Hide LScreen after 15 seconds
      const timeout = setTimeout(() => {
          setPageLoaded(false);
      }, 2500);

      return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, []);

  
    return (
      <main>
        <div className={`banner ${(isMobile && !pageLoaded) ? 'active' : (!isMobile ? 'active': '')}`}>
            <Header/>
          <div className={`container-fluid ${isMobile ? 'text-center' : ''}`}>
            <ImgBanner />
            <div className={`About ${isMobile ? 'text-center' : ''}`} ref={aboutInfoRef}>
              <h1 className="AboutTitle">About Al's-OPSS</h1>
              <div className="AboutInfo">
                <p>
                  Al's-OPSS was made for the advancement of the user experience in most sectors of the internet. For example, the Game, Online shopping, Web development, and the A.I. sectors.
                </p>
                <p>
                  Al's-OPSS was Founded in 2019
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {pageLoaded && <LScreen className={pageLoaded ? 'active' : ''} />} {/* Render LScreen */}
      </main>
    );
  }
  
  export default Main;