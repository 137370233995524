import React from "react";
import './lscreen.css'
import icon from '../logo.png'
function LScreen() {
    return (
        <lscreen>
            <img src={icon}></img>
            <span class="loader"></span>
        </lscreen>
    )
}

export default LScreen;
